import {
  ref, onMounted, getCurrentInstance, reactive, watch, computed
} from 'vue'
import { useStore } from 'vuex'
import moment from 'moment'
import { useRoute, useRouter } from 'vue-router'
import { openTicketUseCase } from '@/domain/usecase'

export default {
  components: {
  },
  setup() {
    const app = getCurrentInstance()
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const {
      $numbers, $toast, $strInd, $confirm
    } = app!.appContext.config.globalProperties
    const titleValue = ref('')
    const descriptionValue = ref('')
    const validate = computed(() => {
      const isDisabled = !(titleValue.value.length > 0 && descriptionValue.value.length > 0)
      const errors: any = []
      if (!(titleValue.value.length > 0)) {
        errors.push('Judul')
      }
      if (!(descriptionValue.value.length > 0)) {
        errors.push('Deskripsi')
      }
      return {
        isDisabled,
        errors: `${errors.join(', ')} harus diisi !!`
      }
    })
    const slugify = (t: string) => t.toLowerCase().replace(/[^\w ]+/g, '').replace(/ +/g, '-')
    const isExpired = async () => {
      const res = moment(store.getters.getOpenTicketData.expiredAt).isBefore(moment())
      if (res && store.getters.getOpenTicketIsAuthen) {
        store.dispatch('setAppOpenticketAuthDefault', { expiredAt: moment().subtract(10, 'minutes') })
      }
      return res
    }
    const submmitTciket = async () => {
      await isExpired()
      if (!store.getters.getOpenTicketIsAuthen) {
        $toast.add({
          severity: 'error', detail: 'Session tidak valid , silahkan hubungi administrator !!', group: 'bc', life: 1500
        })
      } else {
        store.dispatch('showLoading')
        const formData = {
          title: `${titleValue.value}, from:${slugify(store.getters['appActiveUser/getAppActiveUser']?.fullname)}`,
          content: descriptionValue.value
        }
        if (validate.value.isDisabled) {
          $toast.add({
            severity: 'error', detail: validate.value.errors, group: 'bc', life: 1500
          })
        } else {
          titleValue.value = ''
          descriptionValue.value = ''
          const response = await openTicketUseCase.submitData(formData)
          if (response.error) {
            $toast.add({
              severity: 'error', detail: $strInd.toast.errorAllData, group: 'bc', life: 1500
            })
            router.go(-1)
          } else if (!response.result.success) {
            $toast.add({
              severity: 'success', detail: `Gagal mengirim tiket, errors : ${response.result.messages.content.join(', ')}`, group: 'bc', life: 1500
            })
            router.go(-1)
          } else {
            $toast.add({
              severity: 'success', detail: 'Tiket berhasil dikirim', group: 'bc', life: 1500
            })
          }
        }
        store.dispatch('hideLoading')
      }
    }
    const openTicketPage = () => {
      const host = store.getters.getOpenTicketData?.host
      $confirm.require({
        header: 'Konfirmasi',
        message: 'Apakah anda ingin membuka halaman daftar ticket ?',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          // window.open(`${process.env.VUE_APP_API_OPENTICKET}ticket-support/login`, '_blank')
          window.open(`${host}/login`, '_blank')
        },
        reject: () => {
          // alert('reject')
        }
      })
    }
    // const isExpired = (t) => moment('2021-10-01 13:50', 'YYYY-MM-DD HH:mm').isBefore(moment().add(1, 'minutes'))
    const getOpenTicketUser = async () => {
      const responseAllUser = await openTicketUseCase.getAllOtUser()
      // console.log('responseAllUser', responseAllUser)
      // // const responseAllUser = await openTicketUseCase.getOneOtUser()
      if (responseAllUser.error) {
        $toast.add({
          severity: 'error', detail: `${$strInd.toast.errorAllData} (${responseAllUser.message})`, group: 'bc', life: 1500
        })
        router.go(-1)
      } else {
        const account = responseAllUser.result[0]
        const dataOpenTicket = {
          // email: `${account.Username}`,
          // password: account.Password,
          email: 'hjexclient@mail.com',
          password: 'password',
          host: `${account.Url}`
          // host: 'https://monstercode.ip-dynamic.com:8443/helpdesk/'// production
          // host: 'http://monstercode.ip-dynamic.com:8087/helpdesk/' // development
        }
        await store.dispatch('setAppOpenticketAccount', dataOpenTicket)
      }
    }
    const authUser = async () => {
      store.dispatch('showLoading')
      await getOpenTicketUser()
      await isExpired()
      if (!store.getters.getOpenTicketIsAuthen) {
        const email = store.getters.getOpenTicketData?.email
        const password = store.getters.getOpenTicketData?.password
        const host = store.getters.getOpenTicketData?.host
        // store.getters.getOpenTicketData
        const response = await openTicketUseCase.authUser(email, password)
        if (response.error) {
          $toast.add({
            severity: 'error', detail: `${$strInd.toast.errorAllData} (${response.message})`, group: 'bc', life: 1500
          })
          router.go(-1)
        } else {
          /* eslint-disable */
          // console.log('.')
          if (response.result.success) {
            const dataOpenTicket = {
              host,
              email,
              password,
              isAuthenticated: response.result.success,
              token: response.result.token,
              expiredAt: moment(response.result.token_expire).subtract(1, 'minutes'),
              // expiredAt: moment(response.result.token_expire).subtract(400, 'minutes'),
              isExpired: false,
            }
            // console.log('set setexpiredAt', moment(response.result.token_expire).subtract(400, 'minutes').format('YYYY-MM-DD HH:mm'))
            store.dispatch('setAppOpenticketAuth', dataOpenTicket)
            // console.log('store.state.openTicket', store.state.openTicket)
            // console.log('store.getters.getOpenTicketData', store.getters.getOpenTicketData)
            // console.log('store.getters.getOpenTicketData', store.getters.getOpenTicketData.expiredAt?.format('YYYY-MM-DD HH:mm'))
            // console.log('store.getters.getOpenTicketIsAuthen', store.getters.getOpenTicketIsAuthen)
            $toast.add({
              severity: 'success', detail: 'Login Berhasil', group: 'bc', life: 1500
            })
          }
        }
      }
      store.dispatch('hideLoading')
    }

    onMounted(() => {
      titleValue.value = ''
      descriptionValue.value = ''
      authUser()
    })
    return {
      titleValue,
      descriptionValue,
      validate,
      submmitTciket,
      openTicketPage,
      authUser,
      store,
      moment
    }
  }
}
